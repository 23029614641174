import React, { useState, useEffect, useRef } from "react";
import axios from "axios"; 
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';
import TodayIcon from '@material-ui/icons/Today';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from "react-to-print";
import Agenda from "../../../../../components/Common/Agenda";
import { CircularProgress } from "@material-ui/core";
import SearchBar from "../../../../../components/Common/SearchBar";
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        '& > *': {
        //margin: theme.spacing(1),
        width: theme.spacing(25),
        //minHeight: theme.spacing(25),
        borderRadius: '15px !important',
        //padding: theme.spacing(1),
        },
        padding:10,
    },
    accordion: {
        minHeight: '130px !important;',
        paddingTop: '20px !important;',
        /*padding: '-1px -1px !important;',*/
        width: '100% !important',
    },
    accordionSummary: {
        marginBottom: '0px !important',
        padding: '0px 10px !important',
        minHeight: '90px !important',
    },
    /*
    cabecera: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    */
    pie: {
        marginTop: 10,
    },
    diaMes: {
        fontFamily: 'Open Sans',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
        color: '#3465A4'
    },
    agenda: {
        fontFamily: 'Open Sans',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        color: '#A6A5A5'
    },
    lineHR: {
        borderTop: "1px solid #A6A5A5",
        backgroundColor: '#A6A5A5',
        marginLeft: '25px !important',
        marginRight: '25px !important',
    },
    title: {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
    },
    horario: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        color: '#000'
    },
    otrosTextos: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '20px',
    },
    enlacePlanificacion: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '20px',
        marginTop: '20px',
        marginLeft: 20,
    },
    otrosTextosDesplazado: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '20px',
        marginLeft: "16px",
    },
    estado: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        marginLeft: 20,
        marginTop: 10,
    },
    validada: {
        color: "green",
    },
    pendiente: {
        color: "#FF0000",
    },
    fondoDiaActual:
    {
        backgroundColor: "#EBEBEB",
    },
    bordevalidada: {
        borderColor: "green",
        borderStyle: 'groove',
    },
    bordependiente: {
        borderColor: "#FF0000",
        borderStyle: 'groove',
    },

    bordeSemanal: {
        borderColor: "green",
        borderStyle: 'groove',
    },
    bordeMensual: {
        borderColor: "orange",
        borderStyle: 'groove',
    },
    bordeAnual: {
        borderColor: "red",
        borderStyle: 'groove',
    }
    
}));

const PlanningMantenimientoPreventivo = () => {
    const classes = useStyles();
    const componentRef = useRef();
    const [datosMantenimientos, setDatosMantenimientos] = useState([]); 
    const [counter, setCounter] = useState(new Date());
    const [diasSemanaActual, setDiasSemanaActual] = useState([]);
    const semanaActual = useRef({fechInicial: '', fechaFinal: '', anioActual: '', mesActual: ''});
    const [numAvisos, setNumAvisos] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    //let anioActual = '';
    //let mesActual = '';
    let firstDay = '';
    let lastDay = '';
    
    const daysInMonth = (year, month) => new Date(year, month, 0).getDate();

    const changeDataWeek = (diasSumar = 0) => {
        let curr = new Date(); 

        if(semanaActual.current.fechInicial)
            curr = new Date(semanaActual.current.fechInicial);
        
        curr.setDate(curr.getDate() + diasSumar);

        let first = curr.getDate();// - curr.getDay() + 1; 
        let last = first + 6;

        const anioActual = curr.getFullYear();
        const mesActual = curr.getMonth();
        const numeroDiasMes = daysInMonth(anioActual, mesActual+1);

        let dias = [{},{},{},{},{},{},{}];
        let j = 1;
        for(let i=0; i<=6; i++){
            dias[i] = {dia: first + i, mes: mesActual};
            if(dias[i].dia > numeroDiasMes){
                dias[i] = {dia: j++, mes: mesActual === 11 ? 0 : mesActual + 1};
            }            
        }
        
        setDiasSemanaActual(dias);
        firstDay = new Date(curr.setDate(first)).toISOString().split('T')[0];
        lastDay = new Date(curr.setDate(last)).toISOString().split('T')[0];
        semanaActual.current = {fechInicial: firstDay, fechaFinal: lastDay, anio: anioActual, mes: mesActual}
    }
   
    const INTERVAL_TIME = 1000 * 60 * 5; // 5 Minutos 1000 * 60 * 5

    const getData = () => {
        (async () => {
            setIsLoading(true)
            const URL_DATOS = process.env.REACT_APP_API_URL + 'mantenimientopreventivos/planning'
            
            const respDatos = await axios(URL_DATOS);
            if(Array.isArray(respDatos.data) && respDatos.data.length > 0){
                setDatosMantenimientos(respDatos.data)
            } else setDatosMantenimientos([])
            setIsLoading(false)
          })();
    }

    useEffect(() => {
        changeDataWeek();
        getData();
        setCounter(new Date());
    },[])

    useEffect(() => {
        let intervalId;
        intervalId = setInterval(() => {
            getData();
            setCounter(new Date());
          }, INTERVAL_TIME)
          return () => clearInterval(intervalId);
    }, [counter])

    
    const irASemanaAnterior = () => {
        changeDataWeek(-7)
        getData();
        setCounter(new Date());
    }

    const irASemanaSiguiente = () => {
        changeDataWeek(+7)
        getData();
        setCounter(new Date());
    }

    const irASemanaActual = () => {
        window.location.reload();
    }
    
    const imprimirPlanning = () => {
       printData();
    }

    const printData = () => {
        (async () => {
            const URL_DATOS = process.env.REACT_APP_API_URL + `mantenimientopreventivos/printplanningsemanal?fechainicial=${semanaActual.current.fechInicial}&fechafinal=${semanaActual.current.fechaFinal}`
            
            const respDatos = await axios(URL_DATOS);
           
            if(respDatos.data.includes(".pdf"))
                window.open(respDatos.data, "_blank")
            else alert(respDatos.data)
          })();
    }

    return (
    <div className="col-12 col-main "> 
        <SearchBar
            titulo="Servicios"
            claseCabecera = "bg-produccion"
            buscar={false}
            imagenCabecera={"5-4-1-mantenimiento.png"}
        /> 
        <div ref={componentRef} id="planningmantenimientopreventivo" className="bg-cuerpo p-10px">
            <div className="bg-white p-10px">
            <div className="p-1 bg-white mb-3">
                <h6 className="texto-gestion float-left" id="title">
                Planning Mantenimiento Preventivo
                </h6>
            </div>
            <hr className="m-0 bg-gestion mb-1" />
            <div className="principal bg-white cuerpo-h-planning-semanal pr-2 overflow-auto overflow-hiddens table-responsive">

                <div className="row ml-0 mr-0 p-1 mt-4">
                    <div className="col-4"></div>
                    <div className="col-4 text-center">
                        {semanaActual.current.anio}
                        <IconButton aria-label="now" onClick={() => irASemanaActual() }>
                            <TodayIcon titleAccess="Semana actual"/>
                        </IconButton>
                        {/*
                        <IconButton aria-label="imprimir" onClick={() => { imprimirPlanning() }}>
                            <PrintIcon titleAccess="Imprimir"/>
                        </IconButton>
                        */}
                        
                        </div>
                    <div className="col-2"></div>
                    <div className="col-2"><Agenda/></div>

                    <div className="navegacionSemanalPlanning">
                        <IconButton aria-label="previous" onClick={() => irASemanaAnterior() }>
                            <NavigateBeforeIcon titleAccess="Semana anterior"/>
                        </IconButton>
                    </div>

                    {diasSemanaActual &&        
                        diasSemanaActual.map((dia, index) => (
                            <ComponenteDia key={dia.dia} dia={dia.dia} mes={dia.mes} anio={semanaActual.current.anio} indice={index} />  
                        ))
                    }

                    <div className="navegacionSemanalPlanning">
                        <IconButton aria-label="Semana siguiente" onClick={() => irASemanaSiguiente() }>
                            <NavigateNextIcon titleAccess="Semana siguiente"/>
                        </IconButton>
                    </div>

                </div>
                <hr className={`${classes.lineHR} m-0 mb-1`} />
                {!isLoading && (
                <div className="row ml-0 mr-0 p-1 mt-4">
                    <div className="navegacionSemanalPlanning"></div>
                    {diasSemanaActual &&        
                        diasSemanaActual.map((dia, index) => (
                             <ComponenteManosDia 
                                key={dia.dia} 
                                dia={dia.dia} 
                                mes={dia.mes} 
                                anio={semanaActual.current.anio}
                                mantenimientos={datosMantenimientos}                                 
                                />
                        ))
                    }
                    <div className="navegacionSemanalPlanning"></div>
                </div>
                )}
                {isLoading && (
                    <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /> 
                )} 
            </div>
            </div>
        </div>
    </div>   
  );  
}
export default PlanningMantenimientoPreventivo;

const ComponenteDia = ({dia, mes, anio, indice}) => {
    const classes = useStyles();
    const nombresMeses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
    const date = new Date();
    const numeroDiaMes = date.getDate()
    const mesActual = date.getMonth();
    const anioActual = date.getFullYear();
    let esHoy = false;
    if(numeroDiaMes === dia && mesActual === mes && parseInt(anioActual) === parseInt(anio)){
        esHoy = true
    }

    const dateStr = anio + '-' + (mes+1).toString().padStart(2, 0) + '-' + dia.toString().padStart(2, 0);
    const dateTo = new Date(dateStr);
    const day = dateTo.toLocaleDateString("es-ES", { weekday: 'long' });  

    return(
        <div className="anchoColumnaPlanningSemanal"
            >
            <div className={ `${classes.root} ${ esHoy ? classes.fondoDiaActual : ""} titulo-navbar }` } >
                <Typography className={`${classes.diaMes} mr-4`}>{dia} {nombresMeses[mes]}</Typography>
                <Typography className={`${classes.heading} ${classes.agenda} mr-4`}>{day}</Typography>
            </div>
        </div>
    )
}

const ComponenteManosDia = ({dia, mes, anio, mantenimientos}) => {  
    return(
        <div className="anchoColumnaPlanningSemanal"
        id={`listadoManos-${dia}`}>
            <div 
                className="titulo-navbar"
                id={`titulo-navbar-${dia}`}
            >
                {mantenimientos &&        
                    mantenimientos                
                    .map((mantenimiento, index) => (
                        <ComponenteMantenimiento key={index} mantenimiento={mantenimiento} dia={dia} mes={mes} anio={anio}/>
                    )
                )}
            </div>
        </div>
    )   
}

const ComponenteMantenimiento = ({mantenimiento, dia, mes, anio}) => {    
    const classes = useStyles();
    const date = new Date();
    const numeroDiaMes = date.getDate();
    const numeroMes = date.getMonth();
    const numeroAnio = date.getFullYear();

    let formattedDay = ("0" + dia).slice(-2);
    let formattedMonth = ("0" + (mes+1)).slice(-2);
    let formattedDate = anio+"-"+formattedMonth+"-"+formattedDay;     
    
    let esHoy = false;
    if( numeroDiaMes === dia &&
        numeroMes === mes &&
        numeroAnio === anio 
    ){
        esHoy = true
    }

    let visible = false;
    let claseBorde = "";

    if(mantenimiento.TipoPeriodicidad.toLowerCase() === "anual"){
        if(mantenimiento.DiaAnio === formattedDate){ 
            visible = true 
            claseBorde = classes.bordeAnual;
        }
    }

    if(mantenimiento.TipoPeriodicidad.toLowerCase() === "mensual"){
        if(parseInt(mantenimiento.DiaMes) === parseInt(dia)){ 
            visible = true 
            claseBorde = classes.bordeMensual;
        }
    }

    if(mantenimiento.TipoPeriodicidad.toLowerCase() === "semanal"){
        let diaDeLaSemana = 0
        if(mantenimiento.DiaSemana < 7)
            diaDeLaSemana = mantenimiento.DiaSemana
        
        const dateString = anio+"-"+(mes+1)+"-"+ dia;
        const dateObject = new Date(dateString);
        const dayOfWeek = dateObject.getDay();
        
        if(diaDeLaSemana === dayOfWeek){ 
            visible = true 
            claseBorde = classes.bordeSemanal;
        }
    }

    return(
        <>
        { visible && (
        <div 
        className={ `${classes.root} ${ esHoy ? classes.fondoDiaActual : ""} }` }
        id={`mantenimiento-${mantenimiento.Id}`}
        >
            <Accordion className={ `${classes.accordion} ${claseBorde} ` }>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel1a-content"
                    id1="panel1a-header"
                    className={classes.accordionSummary}
                    id={`draggable-accordion${mantenimiento.idPlanificacion}-${mantenimiento.idMano}`}                    
                    >
                    <div className={classes.cabecera2}>
                        <Typography className={classes.horario} color="textSecondary">
                            <ArrowRightIcon />{mantenimiento.Titulo}
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary" >
                            <ArrowRightIcon />{mantenimiento.NombreRecurso}
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary" >
                            <ArrowRightIcon />{mantenimiento.TipoPeriodicidad}
                        </Typography>                        
                    </div>
                </AccordionSummary>
                <AccordionDetails className="d-block" >
                    <div className={classes.pie}>                           
                        {mantenimiento.Descripcion &&
                        <Typography className={classes.otrosTextos} color="textSecondary">
                            <ArrowRightIcon />{mantenimiento.Descripcion}
                        </Typography>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>            
        </div>
        )}
        </>
    )
}