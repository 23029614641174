const columnsMantenimiento = [
    {
      name: "Id",
      label: "Nº",
      options: {
       filter: true,
       sort: false,
      }
     },       
    {
        name: "fecha",
        label: "Fecha",
        options: {
         filter: true,
         sort: false,
        }
       },
       {
        name: "codigo",
        label: "Código",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Subcategoria",
        label: "Subcategoría",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Elemento",
        label: "Elemento",
        options: {
         filter: true,
         sort: true,
        }
       },
       /*
       {
        name: "area",
        label: "Área de Trabajo",
        options: {
         filter: true,
         sort: true,
        }
       },
       */
       {
        name: "tipologia",
        label: "Tipología",
        options: {
         filter: true,
         sort: true,
        }
       },         
       {
        name: "horasTotales",
        label: "Horas totales",
        options: {
         filter: false,
         sort: true,
         setCellProps: () => ({ style: { textAlign: 'right'  }}),
          sortCompare: (order) => {
            return (obj1, obj2) => {            
              let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
              let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          }          
         
        }   
       },
       
       {
        name: "ImporteTotal",
        label: "Importe total",
        options: {
          filter: false,
          sort: true,  
          setCellProps: () => ({ style: { textAlign: 'right'  }}),
          sortCompare: (order) => {
            return (obj1, obj2) => {            
              let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
              let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          }          
        }   
      }, 
       {
          name: "Estado",
          label: "Estado",
          options: {
           filter: true,
           sort: true,           
          }   
       }, 
];
  
export {columnsMantenimiento}; 
  
const columnsReparacion = [
        {
          name: "Id",
          label: "Nº",
          options: {
           filter: true,
           sort: false,
          }
         },  
        {
            name: "fecha",
            label: "Fecha",
            options: {
             filter: true,
             sort: false,
            }
           },
           {
            name: "codigo",
            label: "Código",
            options: {
             filter: true,
             sort: true,
            }
           },
           /*
           {
            name: "area",
            label: "Área de Trabajo",
            options: {
             filter: true,
             sort: true,
            }
           },
           */
           {
            name: "tipologia",
            label: "Tipología",
            options: {
             filter: true,
             sort: true,
            }
           },  
           {
            name: "Elemento",
            label: "Elemento",
            options: {
             filter: true,
             sort: true,
            }
           },
           {
            name: "horasTotales",
            label: "Horas Totales",
            options: {
             filter: true,
             sort: true,
             
            }   
           },            
           {
              name: "Estado",
              label: "Estado",
              options: {
               filter: true,
               sort: true,
               
              }   
             }, 
];
      
export {columnsReparacion}; 

const columnsMaquinaria = [
  {
    name: "Id",
    label: "Nº",
    options: {
     filter: true,
     sort: false,
    }
   },  
  {
      name: "Categoria",
      label: "Categoría",
      options: {
       filter: true,
       sort: false,
      }
     },
     {
      name: "Subcategoria",
      label: "Subcategoría",
      options: {
       filter: true,
       sort: true,
      }
     },     
     {
      name: "Nombre",
      label: "Nombre",
      options: {
       filter: true,
       sort: true,
      }
     },  
     {
      name: "Marca",
      label: "Marca",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "MPL",
      label: "MPL",
      options: {
       filter: true,
       sort: true,
       
      }   
     },  
     {
      name: "Empresa",
      label: "Empresa",
      options: {
       filter: true,
       sort: true,
       
      }   
     },  
     {
        name: "Estado",
        label: "Estado",
        options: {
         filter: true,
         sort: true,
         
        }   
       }, 
];
  
export {columnsMaquinaria};

const columnsActuaciones = [
  {
    name: "Id",
    label: "Nº",
    options: {
     filter: true,
     sort: false,
    }
   },  
  {
    name: "Nombre",
    label: "Nombre",
    options: {
      filter: true,
      sort: true,
    }
  },  
]
export {columnsActuaciones};
      