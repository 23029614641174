import React, { useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DialogForm from "./DialogForm"


const AddButtonToolbar = ({
  title, urlForm, action, campos, urlGoto, type="add", 
  toolTipTitle="Editar", value=null, cerrar=null, maxDialogWidth='md',
  showDeleteDialog = false
}) => {
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if(cerrar === true){
      handleClose()
    }   
},[cerrar])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <>
    <Tooltip title={toolTipTitle}>
        <IconButton className="" onClick={handleClickOpen}>
        {type === "add" && (<AddIcon className=""/>)}
        {type === "edit" && (<EditIcon className=""/>)}
        {type === "view" && (<VisibilityIcon className=""/>)}
        </IconButton>
    </Tooltip>
    <DialogForm 
      title={title}
      isOpen={open} 
      handleCloseDialog={handleClose}
      urlForm={urlForm}
      action={action}
      campos={campos}
      urlGoto={urlGoto}
      type={type}
      value={value}
      maxDialogWidth = {maxDialogWidth}
      showDeleteDialog = {showDeleteDialog}
      />
    </>
  )

}

export default AddButtonToolbar