import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from "axios";
import FormularioEntidadDialog from './FormularioEntidadDialog'

const DialogForm = ({
  title, isOpen, handleCloseDialog, urlForm, action, 
  campos, urlGoto, type, value = null,maxDialogWidth='md',
  formName = "formularioEntidadDialog",
  showDeleteDialog = false, textoBoton2 = "GUARDAR"
}) => {
  const [open, setOpen] = useState(false); 
  const [openDelete, setOpenDelete] = useState(false);

  const handleClose = () => {
    handleCloseDialog();
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenDelete = () => {    
    setOpenDelete(true)
  }

  const handleDeleteOK = () => {
    let datos = {}   
    const user = JSON.parse(localStorage.getItem("user"));
    const AuthStr = "Bearer ".concat(user.access_token);
    const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlForm}/${value}`
   
    datos.username=user.username
    datos.userid=user.id
    
    axios
          .delete(SERVER_URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {   
                  
            if(!isNaN(response.data)){      
              alert("Se ha eliminado correctamente.")   
              window.location.reload();                          
            }else{
              alert("Ha ocurrido un error al eliminar.")
            }
            return;              
          })
          .catch((error) => {
            if( error.response ){
              console.log(error.response.data); // => the response payload 
              alert("Ha ocurrido un error al eliminar. " + error.response.data.message)
            }
          });

  }

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])

  return (
    <>      
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={maxDialogWidth}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>            
          </DialogContentText>
         <FormularioEntidadDialog
          urlForm={urlForm}
          action={action}
          campos={campos}
          urlGoto={urlGoto}
          type={type}
          value={value}
          formName = {formName}
          textoBoton2={textoBoton2}
         />
        </DialogContent>
        <DialogActions>
          {showDeleteDialog && (
            <Button onClick={handleOpenDelete} color="primary">
              Eliminar
            </Button>   
          )}
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>         
        </DialogActions>
      </Dialog>
            
      <Dialog open={openDelete} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={maxDialogWidth}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>            
          </DialogContentText>
        ¿Desea eliminar el contenido? 
        </DialogContent>
        <DialogActions>
          {type === "edit" && (
            <Button onClick={handleDeleteOK} color="primary">
              Eliminar
            </Button>   
          )}
          <Button onClick={handleCloseDelete} color="primary">
            Cancelar
          </Button>         
        </DialogActions>
      </Dialog>
    </>
  );
}
export default DialogForm