import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import LinearProgress from "@material-ui/core/LinearProgress";
import FormularioTablaHojaDePedidos from "./FormularioTablaHojaDePedidos";
import TablaCompra from "./TablaCompra";
import { useHistory } from "react-router";
import { useStyles } from "../../../../../styles/styles";

const FormularioHojaDePedido = ({index, tipoForm}) => { 
 
  
  const [HojaPedido, setHojaPedido] = useState([]) 
  const [campos, setCampos] = useState([]) 
  const { id, modo } = useParams();
  //array para cargar los datos de los formularios correspondientes
  const [listadoCompra, setListadoCompra] = useState([])
  const history = useHistory(); 
  const [ficheros, setFicheros] = useState([])
  const [nombresFicheros, setNombresFicheros] = useState([])

  const methods = useForm();
  
  const classes = useStyles();;
  const { control, handleSubmit} = useForm(); 

  const [openAlert, setOpenAlert] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleListadoTabla=(valor) => {
    setListadoCompra([...listadoCompra,valor])
  }

  const handleRemoveElement = (element) =>{
    setListadoCompra(element) 
  }
    
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    setOpenAlert(false);
  };  

    
  const handleFicheros = (files, filenames) => {      
    setFicheros(files)
    setNombresFicheros(filenames)
  }
 
    useEffect(() => { 
      
     setCampos([
       //0
        {
          required: true,
          label: "Fecha Pedido",
          name: "fecha",
          type: "date",    
          value: '',
          xs: 4
        },
        //1
        {
          urlData: "dic/diccionarios/listadousuarioscomprassolicitante",
          campoEtiqueta: "nombre",
          required: true,
          name: "nombre",
          disabled: false,
          label: "Solicitante",
          type:'combo',
          value: '',
          multiple: false,
          xs: 4
        },
        //2
        {
          urlData: "dic/diccionarios/getdepartamentos",
          campoEtiqueta: "Etiqueta",
          required: true,
          name: "departamentos",
          disabled: false,
          type:'combo',
          label: "Departamento",
          value: '',
          multiple: false,
          xs: 4
        },   
        //3     
        {
          required: false,
          label: "Fecha Autorizacion",
          name: "fechaAutorizacion",
          type: "date",    
          value: '',
          xs: 4
        },
        //4
        {
          urlData: "dic/diccionarios/listadousuarioscomprasautorizante",
          campoEtiqueta: "nombre",
          required: true,
          name: "encargado",
          disabled: false,
          label: "Autorizante",
          type:'combo',
          value: '',
          multiple: false,
          xs: 4
        },
        //5
        {
            urlData: "dic/diccionarios/estadoshojapedido",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "estado",
            disabled: false,
            type:'combo',
            label: "Estado",
            value: '',
            multiple: false,
            xs: 4
        },
        //6
        {
          required: false,
          name: "observaciones",
          disabled: false,
          label: "Observaciones",
          value: '',
          type: 'textarea',
          lineas:3,
          xs: 6
        },
        //7
        {
          required: false,
          label: "Documentacion",
          name: "ficherosadjuntos",
          type: "filesupload",   
          tabla: "mtms_hoja_pedido_adjuntos",
          xs:6
        },
        //8
        {
          required: false,
          label: "Documentacion",
          name: "ficheros",
          type: "files", 
          ficheros:"",    
          tabla: "mtms_hoja_pedido_adjuntos",
          modelo: "api\\modules\\v1\\models\\hojapedido\\HojaPedidoAdjuntos",
          carpeta: "hoja-pedido",
          xs:12
        }
     ])

    const fetchData = async () => {
      setIsLoading(true) 
      if (modo === "W") {

          const respHojaPedidos = await axios(
              `${process.env.REACT_APP_API_URL}hojaspedidos/${id}`
          );                
          setHojaPedido(respHojaPedidos.data)
          
      }
      setIsLoading(false);

  };    
  fetchData(); 
    
},[]);

    
useEffect(() => {
  if (typeof HojaPedido !== "undefined") { 
    if(Object.keys(HojaPedido).length > 0){
      //console.log(HojaPedido)
      setIsLoading(true)  
      campos[0].value = HojaPedido.Fecha_Pedido;
      campos[1].value = HojaPedido.Solicita;
      campos[2].value = HojaPedido.Departamento;
      campos[3].value = HojaPedido.Fecha_Autorizacion;
      campos[4].value = HojaPedido.Autoriza;
      campos[5].value = HojaPedido.Estado;           
      campos[6].value = HojaPedido.Observaciones;
      //campos[7].ficheros = HojaPedido.ficheros;
      campos[8].ficheros = HojaPedido.ficheros;
      setListadoCompra(HojaPedido.articulos);
      setIsLoading(false)        
    }
  }
}, [HojaPedido])



const handleSubmitPost = async (datos) => {
 
  //console.log(datos)
/*
  //datos.fecha = datos.fecha
  datos.nombre = datos.nombre.id
  datos.departamentos = datos.departamentos.Id
  //datos.fechaAutorizacion = datos.fechaAutorizacion
  datos.encargado = datos.encargado.id
  datos.estado = datos.estado.Id
  //datos.observaciones = datos.observaciones
  datos.ficheros=ficheros
  datos.nombresFicheros=nombresFicheros
  //array de valores donde esten todos los variables de la tabla
  */
  datos.listadotabla=listadoCompra
  datos.ficheros=ficheros
  datos.nombresFicheros=nombresFicheros 
  //console.log(datos)

  let user = JSON.parse(localStorage.getItem("user"));
  datos.username=user.username
  datos.userid=user.id
  datos.IdEntidad = id

  const AuthStr = "Bearer ".concat(user.access_token);
  const SERVER_URL = `${process.env.REACT_APP_API_URL}hojaspedidos`

  //console.log(datos)
    if (modo==="W") {
      await axios
        .put(`${SERVER_URL}/${id}`, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {       
          //console.log(response.data) 
          if(!isNaN(response.data)){
            if(parseInt(response.data) === parseInt(id)){                       
                setOpenAlert(true)
                
                setTimeout(function () {
                  window.location.reload();   
                }, 1500);  
            }
          }
          })
          .catch((error) => {
            console.log("error " + error);
      });
    }
    else{
      await axios
        .post(SERVER_URL, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {       
          //console.log(response.data) 
          if(!isNaN(response.data)){                                 
            setOpenAlert(true)   
            history.push({
              pathname: `/administracion/compras/hojaspedidos/${response.data}/W`,
              //action: action + Math.floor(Math.random() * (9999))
            });      
            setTimeout(function () {
              window.location.reload(); 
              /*
              history.push({
                pathname: `/administracion/compras/hojaspedidos/${response.data}/W`,
                //action: action + Math.floor(Math.random() * (9999))
              });  
              */
            }
            , 1500);
          }
          })
          .catch((error) => {
            console.log("error " + error);
      });
    }
}
  

return (
<>    
  {!isLoading && (
  <>
    {/* FORMULARIO */}
    <div className={classes.root}>
        <FormProvider {...methods}>
          <form
            id='formulario-HojaDePedidos'
            onSubmit={handleSubmit((data) => {
            handleSubmitPost(data);
            })}
            className=""
          >

          <Grid className="borderFormulario" container spacing={1}> 
              <FormFieldDate propiedades={campos[0]} control={control}/>
              <FormFieldCombo propiedades={campos[1]} control={control} />
              <FormFieldCombo propiedades={campos[2]} control={control}/>
              <FormFieldDate propiedades={campos[3]} control={control}/>
              <FormFieldCombo propiedades={campos[4]} control={control}/>
              <FormFieldCombo propiedades={campos[5]} control={control}/>
              <FormFieldTextArea propiedades={campos[6]} control={control}/>
              <FormFieldFiles propiedades={campos[8]} control={control} ficheros={handleFicheros}/>
              <FormFieldFileUpload propiedades={campos[7]} control={control} handleFicheros={handleFicheros}/>
              
          </Grid>

            <FormularioTablaHojaDePedidos listadotabla= {handleListadoTabla}/>
                        
          </form>
          </FormProvider>
          <TablaCompra listadoCompra={listadoCompra} ListadoCompraFinal={handleRemoveElement} />
          {openAlert && 
          <AlertaMensaje 
            mensaje={"Hoja de pedido guardada correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          } 
    </div>  
  </>
  )}
    <div>
      {isLoading && 
        <div className={classes.linear}>
              <LinearProgress />
        </div>
      }
    </div>
        </>
    )
};

export default FormularioHojaDePedido;