import React, { useState } from "react";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import {botoneraMantenimiento, botoneraMaquinaria, botoneraActuaciones} from "./BotonesConf"
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import {columnsMantenimiento, columnsMaquinaria, columnsActuaciones} from "./ServicioConf";
import SearchBar from "../../../../../components/Common/SearchBar";
import Pasos from "../../../../../components/Common/Pasos";

const Maquinarias = (props) => {    
  const [idOperacion, setIdOperacion]=useState(parseInt(localStorage.getItem('idOpMaquinaria')) || 0);
     
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [tipoMaquinaria, setTipoMaquinaria]=useState(localStorage.getItem('idTipoMaquinaria') || "Abiertas");    
     
  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }

  const verTabla = (idOperacion) => {   
    //console.log(idOperacion) 
    localStorage.setItem('idOpMaquinaria', idOperacion); 
    setIdOperacion(idOperacion)
  } 

  const handleOperacion = (idO) =>{  
    //console.log(idO)      
    localStorage.setItem('idTipoMaquinaria', idO);     
    setTipoMaquinaria(idO)
  } 

  let columnasCuerpoTabla = columnsMantenimiento;//null; //columnsMantenimiento;
  //let urlDatosCuerpoTabla = "mantenimientos";
  let urlDoubleClickCuerpoTabla = null; //"maquinarias/maquinaria/";
  let columnIdCuerpoTabla = "Id";
  let botonaccionCuerpoTabla = botoneraMantenimiento;
  let ordenColumnas = null;
  let footer = null;

  let filtroToolbar = true
  let filtroToolbarOpciones = ['Abiertas', 'Cerradas', 'Todas']
  let urlDatos = null;//tipoMaquinaria === "Abiertas" ? "mantenimientos?estado=1" : ( tipoMaquinaria === "Cerradas" ? "mantenimientos?estado=2" : "mantenimientos");

  if(idOperacion === 0){ // CARPETAS MAQUINARIA
    columnasCuerpoTabla = columnsMantenimiento;
    urlDoubleClickCuerpoTabla = "maquinarias/maquinaria/";
    columnIdCuerpoTabla = "Id";
    botonaccionCuerpoTabla = botoneraMantenimiento;
    urlDatos = tipoMaquinaria === "Abiertas" ? "mantenimientos?estado=1" : ( tipoMaquinaria === "Cerradas" ? "mantenimientos?estado=2" : "mantenimientos");
    //ordenColumnas = [0,1,2,3,4,5,6];
    footer = [
      {campo: 'horasTotales', titulo: 'Total horas', formato: "int"},
      {campo: 'ImporteTotal', titulo: 'Total importe', formato: "currency"},
    ]   
  }
  
  if(idOperacion === 1){ //FICHAS DE ACTUACIONES
    columnasCuerpoTabla = columnsActuaciones;
    urlDoubleClickCuerpoTabla = "maquinarias/actuaciones/";
    columnIdCuerpoTabla = "Id";
    urlDatos = "recursos/propios";
    filtroToolbar = false
    filtroToolbarOpciones = []
    ordenColumnas = [0,1];
    botonaccionCuerpoTabla = botoneraActuaciones;
  }

  if(idOperacion === 2){ //FICHAS DE MAQUINARIA
    columnasCuerpoTabla = columnsMaquinaria;
    urlDoubleClickCuerpoTabla = "maquinarias/fichamaquinaria/";
    columnIdCuerpoTabla = "Id";
    urlDatos = "recursos/propios";
    filtroToolbar = false
    filtroToolbarOpciones = []
    ordenColumnas = [0,1,2,3,4,5,6,7];
    botonaccionCuerpoTabla = botoneraMaquinaria;
  }
  

  return (           
      <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Servicios"
          claseCabecera = "bg-produccion"
          buscar={false}
          imagenCabecera={"5-4-1-mantenimiento.png"}
        />   
        
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">   
          <div className="p-1 bg-white mb-3">
              <h6 className="texto-comercial float-left" id="title">
                Maquinaria
              </h6>
            </div>
            <hr className="m-0 bg-comercial mb-1" /> 
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">      

            <Pasos pasos={['Carpetas', 'Actuaciones', 'Fichas']} idPasoActivo={idOperacion} changePasoActivo={verTabla} />       

             <CuerpoTablaPiezas
                columnas = {columnasCuerpoTabla}
                urlDatos = {urlDatos}
                urlDoubleClick = {urlDoubleClickCuerpoTabla}
                columnId = {columnIdCuerpoTabla}
                getFilaSeleccionada={marcarFila}
                ordenColumnas = {ordenColumnas}
                filtroToolbar={filtroToolbar}
                filtroToolbarOpciones = {filtroToolbarOpciones}
                filtroToolbarValor = {tipoMaquinaria}
                filtroToolbarChangeValor={handleOperacion}
                customFooter={footer} 
                filtroEjercicio = {true}
                /> 
            </div>
          </div>

          <div className="bg-cuerpo">
            <div className="bg-white">
              <TableAcciones                
                filaSeleccionada={filaSeleccionada}                
                botonaccion={botonaccionCuerpoTabla}
              />            
            </div>
          </div>

        </div>
          
      </div>   
    
  );
}
export default Maquinarias;