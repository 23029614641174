import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import IconButton from "@material-ui/core/IconButton";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { useHistory } from "react-router";
import SearchBar from "../../../../../components/Common/SearchBar";
import MigasDePan from "../../../../../components/Common/MigasDePan";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import { CircularProgress } from "@material-ui/core";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";

const handleClickOpen = (fichero) => {   
  console.log(fichero)
  window.open(fichero, "_blank")
};

const Actuaciones = (props) => {
    const history = useHistory();
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [datos, setDatos] = useState();
    const [filaSeleccionada, setFilaSeleccionada] = useState(-1);

    const marcarFila = (fila) => {
      setFilaSeleccionada(fila);
    }
    
    useEffect(() => {
        setIsLoading(true)
    },[props.location.action])

    useEffect(() => {           
        if (isLoading && modo === "R") {
          const fetchData = async () => {
            const respAlmacen = await axios( process.env.REACT_APP_API_URL + "recursos/actuacionesrecurso/" + id);
            //console.log(process.env.REACT_APP_API_URL + "recursos/actuacionesrecurso/" + id)
            if (respAlmacen.data) {              
                console.log(respAlmacen.data)
              setDatos(respAlmacen.data);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
    
    
        if (!modo) {      
          setIsLoading(false);
        }
    }, [isLoading, datos, modo, id]);

    const botoneraAcciones = [        
        {
          etiqueta: "Imprimir Informe de Actuaciones",
          function: (e) => handlePrintReport(), 
          activo: true    
        }        
    ];

    const handlePrintReport = () => {

      const fetchData = async () => {
          setIsLoading(true);
          try{
              const respdatos = await axios(`${process.env.REACT_APP_API_URL}recursos/imprimeinformeactuaciones/${id}`)          
              //console.log(respdatos.data)
              if (respdatos.data) {
                if(respdatos.data.includes(".pdf"))
                  window.open(respdatos.data, "_blank")                
          }
          }catch(error){
              console.log(error)
          }
          finally{
              setIsLoading(false);
          }
          };
          fetchData();      
    }

    const urlsMigasdepan = [
        {titulo: "Maquinaria", colorTexto: "texto-comercial", urlTo: "/servicio/maquinarias"},
        {titulo: "Actuaciones", colorTexto: "texto-comercial", urlTo: `/servicio/maquinarias/actuaciones/${id}/${modo}`},
    ]
      
    const codigo = typeof datos !== "undefined" ? datos.codigo : "";
    const urlDatos = "recursos/actuacionesrecurso/" + id
    const ordenColumnas = null;
    const filtroToolbar = false
    const filtroToolbarOpciones = []
    const footer = null;

    return(
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Servicios"
              claseCabecera = "bg-produccion"
              buscar={false}
              imagenCabecera={"5-4-1-mantenimiento.png"}
            />
    
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                  <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>
    
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                      
                      <CuerpoTablaPiezas
                        columnas = {columnasCuerpoTabla}
                        urlDatos = {urlDatos}
                        urlDoubleClick = {""}
                        columnId = {"Id"}
                        getFilaSeleccionada={marcarFila}
                        ordenColumnas = {ordenColumnas}
                        filtroToolbar={filtroToolbar}
                        filtroToolbarOpciones = {filtroToolbarOpciones}
                        //filtroToolbarValor = {tipoMaquinaria}
                        //filtroToolbarChangeValor={handleOperacion}
                        customFooter={footer} 
                        filtroEjercicio = {false}
                      /> 
              
                    </div>
                  </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
    )

}
export default Actuaciones;

const columnasCuerpoTabla = [
  {
    name: "Id",
    label: "Nº",
    options: {
     filter: true,
     sort: false,
    }
  },
  {
    name: "Fecha",
    label: "Fecha",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "Tipologia",
    label: "Tipologia",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "Codigo",
    label: "Carpeta",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "NombreRecurso",
    label: "Nombre",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "Estado",
    label: "Estado",
    options: {
      filter: true,
      sort: false,
    }
  },      
  {
    name: "Encargado",
    label: "Encargado",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "Observaciones",
    label: "Observaciones",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "urlDocumentos",
    label: "Documento",
    options: {
     filter: false,
     sort: true,
     customBodyRender: (value, tableMeta, updateValue) => { 
        console.log(value) 
        if (value.length > 0){
          return (
            value.map((file) => (
              <React.Fragment key={file.Id}>
                <IconButton className="" onClick={() => handleClickOpen(file.Ubicacion)}>
                <PictureAsPdfIcon className=""/>
                <p className="MuiTableCell-root">{file.Nombre}</p>
                </IconButton> 
              </React.Fragment>
              )) 
          );
        }
      }
    }
   },
]
