import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import moment from "moment";
const SPACED_DATE_FORMAT = "DD/MM/YYYY";

const handleClickOpen = (fichero) => {   
  window.open(fichero, "_blank")
};

const columnsFacturasUnicas = [
    {
      name: "Id",
      label: "Nº",
      options: {
       filter: false,
       sort: true,
       sortCompare: (order) => {
        return (obj1, obj2) => {          
          let val1 = parseFloat(obj1.data, 10);
          let val2 = parseFloat(obj2.data, 10);
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
      }
      }
     },  
    {
      name: "Origen",
      label: "Origen",
      options: {
        filter: true,
        sort: false,
      }
    },      
    {
      name: "Buque",
      label: "Buque",
      options: {
        filter: true,
        sort: false,
      }
    },        
    {
      name: "Fecha_Factura",
      label: "Fecha Factura",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value =>
          moment(new Date(value)).format(SPACED_DATE_FORMAT) 
      }
    },  
    {
      name: "Codigo",
      label: "Cod Factura",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Cliente",
      label: "Cliente",
      options: {
        filter: true,
        sort: true,         
      }   
    }, 
    {
      name: "Base_Imponible",
      label: "Base imponible",
      options: {
        filter: false,
        sort: true,  
        setCellProps: () => ({ style: { textAlign: 'right'  }}),
        sortCompare: (order) => {
          return (obj1, obj2) => {            
            let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
            let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }          
      }   
    }, 
    
    {
      name: "Total_Impuestos",
      label: "IGIC",
      options: {
        filter: false,
        sort: true,   
        setCellProps: () => ({ style: { textAlign: 'right'  }}),
        sortCompare: (order) => {
          return (obj1, obj2) => {            
            let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
            let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }       
      }   
    }, 
    {
      name: "IRPF",
      label: "IRPF",
      options: {
        filter: false,
        sort: true, 
        setCellProps: () => ({ style: { textAlign: 'right'  }}), 
        sortCompare: (order) => {
          return (obj1, obj2) => {            
            let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
            let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }       
      }   
    }, 
    {
      name: "Total_Factura",
      label: "Importe",
      options: {
        filter: false,
        sort: true,  
        setCellProps: () => ({ style: { textAlign: 'right'  }}),
        sortCompare: (order) => {
          return (obj1, obj2) => {            
            let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
            let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }       
      }   
    }, 
    {
      name: "Estado",
      label: "Estado",
      options: {
        filter: true,
        sort: true,           
      }   
    },  
    {
      name: "urlDocumento",
      label: "Documento",
      options: {
       filter: false,
       sort: true,
       customBodyRender: (value, tableMeta, updateValue) => {  
         if (value)      
        return (
          <IconButton className="" onClick={() => handleClickOpen(value)}>
          <PictureAsPdfIcon className=""/>
          </IconButton>          
        );
        }
      }
     },
         
];
  
export {columnsFacturasUnicas};

const columnsFacturasRectificativas = [
  {
    name: "Id",
    label: "Nº",
    options: {
     filter: false,
     sort: true,
     sortCompare: (order) => {
      return (obj1, obj2) => {          
        let val1 = parseFloat(obj1.data, 10);
        let val2 = parseFloat(obj2.data, 10);
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
    }
  },
     {
      name: "Cliente",
      label: "Cliente",
      options: {
       filter: true,
       sort: true,       
      } 
     },
     {
      name: "Fecha_Factura",
      label: "Fecha Factura",
      options: {
       filter: false,
       sort: true,
       customBodyRender: value =>
          moment(new Date(value)).format(SPACED_DATE_FORMAT)      
      }
     },  
     {
      name: "Codigo",
      label: "Cod Factura",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "FacturaOrigen",
      label: "Factura Rectificada",
      options: {
       filter: true,
       sort: true,
      }
     },  
     {
      name: "Cliente",
      label: "Cliente",
      options: {
       filter: true,
       sort: true,       
      }   
     }, 
     {
      name: "Base_Imponible",
      label: "Base imponible",
      options: {
        filter: false,
        sort: true,  
        setCellProps: () => ({ style: { textAlign: 'right'  }}),
        sortCompare: (order) => {
          return (obj1, obj2) => {            
            let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
            let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }          
      }   
    }, 
    {
      name: "Total_Impuestos",
      label: "IGIC",
      options: {
        filter: false,
        sort: true,   
        setCellProps: () => ({ style: { textAlign: 'right'  }}),
        sortCompare: (order) => {
          return (obj1, obj2) => {            
            let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
            let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }       
      }   
    }, 
    {
      name: "IRPF",
      label: "IRPF",
      options: {
        filter: false,
        sort: true, 
        setCellProps: () => ({ style: { textAlign: 'right'  }}), 
        sortCompare: (order) => {
          return (obj1, obj2) => {            
            let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
            let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }       
      }   
    },
     {
      name: "Total_Factura",
      label: "Importe",
      options: {
       filter: false,
       sort: true,   
       setCellProps: () => ({ style: { textAlign: 'right'  }}), 
       sortCompare: (order) => {
        return (obj1, obj2) => {            
          let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
          let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
      }       
      }   
     }, 
     {
        name: "Estado",
        label: "Estado",
        options: {
         filter: true,
         sort: true,
         
        }   
       },
       {
        name: "urlDocumento",
        label: "Documento",
        options: {
         filter: false,
         sort: true,
         customBodyRender: (value, tableMeta, updateValue) => {             
          if (value)      
            return (
              <IconButton className="" onClick={() => handleClickOpen(value)}>
              <PictureAsPdfIcon className=""/>
              </IconButton>              
            );
          }
        }
       },  
       
];

export {columnsFacturasRectificativas};

const columnsFacturasRecurrentes = [
      {
        name: "Id",
        label: "Nº",
        options: {
         filter: false,
         sort: true,
         sortCompare: (order) => {
          return (obj1, obj2) => {          
            let val1 = parseFloat(obj1.data, 10);
            let val2 = parseFloat(obj2.data, 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
        }
       }, 
         {
          name: "Fecha_Factura",
          label: "Fecha Factura",
          options: {
           filter: false,
           sort: true,
           customBodyRender: value =>
              moment(new Date(value)).format(SPACED_DATE_FORMAT) 
          }
         },
         {
          name: "Codigo",
          label: "Cod Factura",
          options: {
           filter: true,
           sort: true,
          }
         },
         {
          name: "cop",
          label: "COP",
          options: {
           filter: true,
           sort: true,
          }
         },
         {
          name: "Buque",
          label: "Buque",
          options: {
            filter: true,
            sort: true,
          }
         },
         {
          name: "subcategoria",
          label: "Subcategoría",
          options: {
           filter: true,
           sort: true,
          }
         },

         {
          name: "Cliente",
          label: "Cliente",
          options: {
           filter: true,
           sort: true,
          }
         },  
         {
          name: "Base_Imponible",
          label: "Base imponible",
          options: {
            filter: false,
            sort: true,  
            setCellProps: () => ({ style: { textAlign: 'right'  }}),
            sortCompare: (order) => {
              return (obj1, obj2) => {            
                let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
                let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
                return (val1 - val2) * (order === 'asc' ? 1 : -1);
              };
            }          
          }   
        }, 
        {
          name: "Total_Impuestos",
          label: "IGIC",
          options: {
            filter: false,
            sort: true,   
            setCellProps: () => ({ style: { textAlign: 'right'  }}),
            sortCompare: (order) => {
              return (obj1, obj2) => {            
                let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
                let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
                return (val1 - val2) * (order === 'asc' ? 1 : -1);
              };
            }       
          }   
        }, 
        {
          name: "IRPF",
          label: "IRPF",
          options: {
            filter: false,
            sort: true, 
            setCellProps: () => ({ style: { textAlign: 'right'  }}), 
            sortCompare: (order) => {
              return (obj1, obj2) => {            
                let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
                let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
                return (val1 - val2) * (order === 'asc' ? 1 : -1);
              };
            }       
          }   
        }, 
         {
          name: "Total_Factura",
          label: "Importe",
          options: {
           filter: false,
           sort: true,
           setCellProps: () => ({ style: { textAlign: 'right'  }}), 
           sortCompare: (order) => {
            return (obj1, obj2) => {            
              let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
              let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          }       
          }
         }, 
         {
            name: "Estado",
            label: "Estado",
            options: {
             filter: true,
             sort: true,
             
            } ,
           },
           {
            name: "urlDocumento",
            label: "Documento",
            options: {
             filter: false,
             sort: true,
             customBodyRender: (value, tableMeta, updateValue) => {                 
              if (value)      
                return (
                  <IconButton className="" onClick={() => handleClickOpen(value)}>
                  <PictureAsPdfIcon className=""/>
                  </IconButton>                
                );
              }
            }
           },
           
];
    
export {columnsFacturasRecurrentes};

const columnsPlantillasRecurrentes = [
        {
          name: "Id",
          label: "Nº",
          options: {
           filter: false,
           sort: true,
           sortCompare: (order) => {
            return (obj1, obj2) => {          
              let val1 = parseFloat(obj1.data, 10);
              let val2 = parseFloat(obj2.data, 10);
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          }
          }
         }, 
           {
            name: "Fecha_Factura",
            label: "Fecha Plantilla",
            options: {
             filter: false,
             sort: true,
             customBodyRender: value =>
              moment(new Date(value)).format(SPACED_DATE_FORMAT) 
            }
           },           
           {
            name: "Cliente",
            label: "Cliente",
            options: {
             filter: true,
             sort: true,
            }
           },   
           {
            name: "Total_Factura",
            label: "Importe",
            options: {
             filter: false,
             sort: true,
             sortCompare: (order) => {
              return (obj1, obj2) => {          
                let val1 = parseFloat(obj1.data, 10);
                let val2 = parseFloat(obj2.data, 10);
                return (val1 - val2) * (order === 'asc' ? 1 : -1);
              };
            }
            }
           }, 
           {
              name: "Estado",
              label: "Estado",
              options: {
               filter: true,
               sort: true,
               
              } ,
             }, 
             {
              name: "Periodicidad",
              label: "Periodicidad",
              options: {
               filter: true,
               sort: true,
               
              } ,
             }, 
             {
              name: "Inicio_Periodo",
              label: "Inicio periodo",
              options: {
               filter: false,
               sort: true,
               customBodyRender: value =>
                moment(new Date(value)).format(SPACED_DATE_FORMAT) 
               
              } ,
             },  
             {
              name: "Fin_Periodo",
              label: "Fin periodo",
              options: {
               filter: false,
               sort: true,
               customBodyRender: value =>
                  moment(new Date(value)).format(SPACED_DATE_FORMAT) 
               
              } , 
             }, 
             
];
      
export {columnsPlantillasRecurrentes};

const columnsFacturasTodas = [
  {
    name: "Id",
    label: "Nº",
    options: {
     filter: false,
     sort: true,
     sortCompare: (order) => {
      return (obj1, obj2) => {          
        let val1 = parseFloat(obj1.data, 10);
        let val2 = parseFloat(obj2.data, 10);
        return (val1 - val2) * (order === 'asc' ? 1 : -1);
      };
    }
    }
   },  
   /*
  {
    name: "Origen",
    label: "Origen",
    options: {
      filter: false,
      sort: false,
    }
  },  
  */     
  {
    name: "Fecha_Factura",
    label: "Fecha Factura",
    options: {
      filter: false,
      sort: true,    
      customBodyRender: value =>
          moment(new Date(value)).format(SPACED_DATE_FORMAT)   
    }
  },  
  {
    name: "Codigo",
    label: "Cod Factura",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "Cliente",
    label: "Cliente",
    options: {
      filter: true,
      sort: true,         
    }   
  }, 
  {
    name: "Base_Imponible",
    label: "Base imponible",
    options: {
      filter: false,
      sort: true,  
      sortCompare: (order) => {
        return (obj1, obj2) => {            
          let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
          let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
      }          
    }   
  }, 
  {
    name: "Total_Impuestos",
    label: "IGIC",
    options: {
      filter: false,
      sort: true,   
      sortCompare: (order) => {
        return (obj1, obj2) => {            
          let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
          let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
      }       
    }   
  }, 
  {
    name: "IRPF",
    label: "IRPF",
    options: {
      filter: false,
      sort: true,  
      sortCompare: (order) => {
        return (obj1, obj2) => {            
          let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
          let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
      }       
    }   
  }, 
  {
    name: "Total_Factura",
    label: "Importe",
    options: {
      filter: false,
      sort: true, 
      sortCompare: (order) => {
        return (obj1, obj2) => {            
          let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
          let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
      }       
    }   
  }, 
  {
    name: "Estado",
    label: "Estado",
    options: {
      filter: true,
      sort: true,           
    }   
  }, 
  {
    name: "tipo",
    label: "Tipo",
    options: {
      filter: true,
      sort: true,           
    }   
  }, 
  {
    name: "IdBuque",
    label: "IdBuque",
    options: {
      filter: false,
      display: false,
      sort: false,           
    }   
  }, 

  {
    name: "Buque",
    label: "Buque",
    options: {
      filter: true,
      sort: true,
      filterType: 'multiselect',
    }   
  }, 
  {
    name: "urlDocumento",
    label: "Documento",
    options: {
     filter: false,
     sort: true,
     customBodyRender: (value, tableMeta, updateValue) => { 
      if (value)      
        return (        
          <IconButton className="" onClick={() => handleClickOpen(value)}>
          <PictureAsPdfIcon className=""/>
          </IconButton>          
        );
      }
    }
   },
       
];

export {columnsFacturasTodas};


