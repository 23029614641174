import React from "react";
import { TEXT_LABELS } from "../../../../../configuraciones/conf";
import AddButtonToolbar from "../../../../../components/Common/AddButtonToolBar";



const OPTIONS_MANTENIMIENTO_PREVENTIVO = {
    filterType: 'checkbox',
    print: false,
    download: false,
    pagination: false,
    selectableRows: 'single',
    expandableRows: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    elevation:0,   
    textLabels: TEXT_LABELS,
};

const CAMPOS_MANTENIMIENTO_PREVENTIVO = [
    {
        urlData: "dic/diccionarios/mantenimientoperiodicidad",
        campoEtiqueta: "Etiqueta",
        required: true,
        label: "Periodicidad",
        name: "periodicidad",
        type: "combo",  
        value: '',
        disabled: false, 
        multiple:false,
        onChangeValue: ( value ) => {
            
            if(value.Id === 1){
                CAMPOS_MANTENIMIENTO_PREVENTIVO[1].disabled = false
            }
        },
        xs:3
    },    
    {
        urlData: "dic/diccionarios/diasdelasemana",
        campoEtiqueta: "diasemana",
        required: false,
        label: "Día de la semana",
        name: "diasemana",
        disabled: true,
        type: "combo",           
        multiple:false,   
        value: '',
        xs:3,
    }, 
    {
        required: false,
        label: "Día del mes:",
        name: "diames",
        type: "date",    
        value: "",
        disabled: true,
        xs:3
    },
    {
        required: false,
        label: "Día del año:",
        name: "diaanio",
        type: "date",    
        value: "",
        disabled: true,
        xs:3
    },
    {    
        required: true,
        label: "Título:",
        name: "titulo",
        type: "text",    
        disabled: false, 
        value: '',                
        xs:12
    }, 
    {
        required: true,
        label: "Descripción",
        name: "descripcion",
        disabled: false, 
        type: "textArea",
        lineas: 4,                
        value: '',
        xs:12
    },
];

const COLUMNS_MANTENIMIENTO_PREVENTIVO = [
    {   //0
        name: "Id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: 'excluded',
         }
    },
    {   //1
        name: "Fecha",
        label: "Fecha",
        options: {
         filter: true,
         sort: true,
        }   
    },
    {   //2
        name: "Titulo",
        label: "Título",
        options: {
         filter: true,
         sort: true,
        }
    },
    {   //3
        name: "Descripcion",
        label: "Descripción",
        options: {
         filter: true,
         sort: true,
        }
    },
    {   //4
        name: "Periodicidad",
        label: "Periodicidad",
        options: {
         filter: true,
         sort: true,
        }
    },
    {   //5
        name: "Dia",
        label: "Día",
        options: {
         filter: true,
         sort: true,
        }
    },
    {   //6
        name: "PeriodicidadObbj",
        label: "PeriodicidadObj",
        options: {
         filter: false,
         sort: false,
         display: 'excluded',
        }
    },
    {   //7
        name: "DiaSemana",
        label: "DiaSemana",
        options: {
          filter: false,
          sort: false,
          display: 'excluded',
         }
    },
    {   //8
        name: "DiaMes",
        label: "DiaMes",
        options: {
          filter: false,
          sort: false,
          display: 'excluded',
         }
    },
    {   //9
        name: "DiaAnio",
        label: "DiaAnio",
        options: {
          filter: false,
          sort: false,
          display: 'excluded',
         }
    },
    {   //10
        name: "DiaSemanaObj",
        label: "DiaSemanaObj",
        options: {
          filter: false,
          sort: false,
          display: 'excluded',
         }
    },

    {
        name: "Editar",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <AddButtonToolbar
                    title = "Formulario Mantenimiento Preventivo"
                    type="edit"
                    urlForm = "mantenimientopreventivos"
                    action = {() => window.location.reload()}
                    urlGoto = "/servicio/maquinarias/fichamaquinaria/"
                    campos = {[
                        {
                            urlData: "dic/diccionarios/mantenimientoperiodicidad",
                            campoEtiqueta: "Etiqueta",
                            required: true,
                            label: "Periodicidad",
                            name: "periodicidad",
                            type: "combo",  
                            value: tableMeta.rowData[6],
                            disabled: false, 
                            multiple:false,
                            onChangeValue: ( value ) => {
                                
                                if(value.Id === 1){
                                    CAMPOS_MANTENIMIENTO_PREVENTIVO[1].disabled = false
                                }
                            },
                            xs:3
                        },    
                        {
                            urlData: "dic/diccionarios/diasdelasemana",
                            campoEtiqueta: "diasemana",
                            required: false,
                            label: "Día de la semana",
                            name: "diasemana",
                            disabled: false,
                            type: "combo",           
                            multiple:false,   
                            value: tableMeta.rowData[10] ? tableMeta.rowData[10] : '',
                            xs:3,
                        }, 
                        {
                            required: false,
                            label: "Día del mes:",
                            name: "diames",
                            type: "date",    
                            value: tableMeta.rowData[8],
                            disabled: false,
                            xs:3
                        },
                        {
                            required: false,
                            label: "Día del año:",
                            name: "diaanio",
                            type: "date",    
                            value: tableMeta.rowData[9],
                            disabled: false,
                            xs:3
                        },
                        {    
                            required: true,
                            label: "Título:",
                            name: "titulo",
                            type: "text",    
                            disabled: false, 
                            value: tableMeta.rowData[2],                
                            xs:12
                        }, 
                        {
                            required: true,
                            label: "Descripción",
                            name: "descripcion",
                            disabled: false, 
                            type: "textArea",
                            lineas: 4,                
                            value: tableMeta.rowData[3],
                            xs:12
                        },
                    ]}
                    value={tableMeta.rowData[0]}
                    showDeleteDialog = {true}
                />
            )
          } 
            
        }    
    },
    {
        name: "Ver",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <AddButtonToolbar
                    title = "Formulario Mantenimiento Preventivo"
                    type="view"
                    urlForm = "mantenimientopreventivos"
                    action = ""
                    urlGoto = "/servicio/maquinarias/fichamaquinaria/"
                    campos = {[
                        {
                            urlData: "dic/diccionarios/mantenimientoperiodicidad",
                            campoEtiqueta: "Etiqueta",
                            required: true,
                            label: "Periodicidad",
                            name: "periodicidad",
                            type: "combo",  
                            value: tableMeta.rowData[6],
                            disabled: true, 
                            multiple:false,
                            onChangeValue: ( value ) => {
                                
                                if(value.Id === 1){
                                    CAMPOS_MANTENIMIENTO_PREVENTIVO[1].disabled = false
                                }
                            },
                            xs:3
                        },    
                        {
                            urlData: "dic/diccionarios/diasdelasemana",
                            campoEtiqueta: "diasemana",
                            required: false,
                            label: "Día de la semana",
                            name: "diasemana",
                            disabled: true,
                            type: "combo",           
                            multiple:false,   
                            value: tableMeta.rowData[10] ? tableMeta.rowData[10] : '',
                            xs:3,
                        }, 
                        {
                            required: false,
                            label: "Día del mes:",
                            name: "diames",
                            type: "date",    
                            value: tableMeta.rowData[8],
                            disabled: true,
                            xs:3
                        },
                        {
                            required: false,
                            label: "Día del año:",
                            name: "diaanio",
                            type: "date",    
                            value: tableMeta.rowData[9],
                            disabled: true,
                            xs:3
                        },
                        {    
                            required: true,
                            label: "Título:",
                            name: "titulo",
                            type: "text",    
                            disabled: true, 
                            value: tableMeta.rowData[2],                
                            xs:12
                        }, 
                        {
                            required: true,
                            label: "Descripción",
                            name: "descripcion",
                            disabled: true, 
                            type: "textArea",
                            lineas: 4,                
                            value: tableMeta.rowData[3],
                            xs:12
                        },
                    ]}
                    value={tableMeta.rowData[0]}
                />
            )
          } 
            
        }    
    },
];

export {OPTIONS_MANTENIMIENTO_PREVENTIVO, CAMPOS_MANTENIMIENTO_PREVENTIVO, COLUMNS_MANTENIMIENTO_PREVENTIVO}