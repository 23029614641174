const COLUMNS_CUENTAS = [
    {
      name: "Numero",
      label: "Número",
      options: {
       filter: false,
       sort: true,
       display: true,
       download: false
      }
     },
    {
     name: "Estado",
     label: "Estado",
     options: {
      filter: true,
      sort: true,
      download: true
     }
    },
    {
      name: "Tipologia",
      label: "Tipología",
      options: {
       filter: true,
       sort: true,
       download: true
      }
     },
    {
     name: "Sector",
     label: "Sector",
     options: {
      filter: true,
      sort: true,
      download: true
     }
    },
    {
     name: "Razon_Social",
     label: "Nombre",
     options: {
      filter: false,
      sort: true,
      download: true
     }
    },
    {
     name: "Ciudad",
     label: "Ciudad",
     options: {
      filter: true,
      sort: true,
      download: true
     }   
    },
    {
      name: "Pais",
      label: "País",
      options: {
       filter: true,
       sort: true,
       download: true
      }   
    },   
    {
      name: "CIF",
      label: "CIF",
      options: {
       filter: false,
       sort: true,
       display: false,
       download: true
      }   
    },
    {
      name: "Vat_Number",
      label: "Vat Number",
      options: {
       filter: false,
       sort: true,
       display: false,
       download: true
      }   
    },
    {
      name: "Email",
      label: "Email",
      options: {
       filter: false,
       sort: true,
       display: false,
       download: true
      }   
    },
    {
      name: "Web",
      label: "Web",
      options: {
       filter: false,
       sort: true,
       display: false,
       download: true
      }   
    },
    {
      name: "EmpresaMatirz",
      label: "Empresa Matriz",
      options: {
       filter: true,
       sort: true,
       display: false,
       download: true
      }   
    },
    {
      name: "Observaciones",
      label: "Observaciones",
      options: {
       filter: false,
       sort: true,
       display: false,
       download: true
      }   
    },


];
export {COLUMNS_CUENTAS};

const BOTONERA_ACCIONES = [
  { etiqueta: "Crear Cuenta", url: "/crm/cuentas/cuenta", activo: true },
  {
    etiqueta: "Editar Cuenta",
    url: "/crm/cuentas/cuenta/filaSeleccionada/W",
    activo: false,
  }, 
];  

export {BOTONERA_ACCIONES};
